<template>
  <div class="ud-body">
    <!-- 搜索表单 -->
    <a-card :bordered="true">
      <a-form
        :model="where"
        class="form"
        :label-col="{ md: { span: 8 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 16 }, sm: { span: 24 } }"
      >
        <a-row>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="充电柜编码:">
              <a-input
                v-model:value="where.cabinetCode"
                placeholder="请输入充电柜编码"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="充电柜名称:">
              <a-input
                v-model:value="where.cabinetName"
                placeholder="请输入充电柜名称"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="电池编号:">
              <a-input
                v-model:value.trim="where.batteryCode"
                placeholder="请输入电池编号"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商:">
              <a-select
                v-model:value="where.operatorId"
                placeholder="请选择所属运营商"
                @change="changeOperator"
                allow-clear
              >
                <a-select-option
                  v-for="item in operatorList"
                  :key="item.operatorId"
                  :value="item.operatorId"
                >
                  {{ item.operatorName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属租赁点" name="pointId">
              <a-select
                v-model:value="where.pointId"
                placeholder="请选择所属租赁点"
              >
                <a-select-option
                  :value="item.pointId"
                  v-for="(item, index) in pointList"
                  :key="index"
                  >{{ item.pointName }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="厂家:">
              <a-select
                v-model:value="where.brand"
                placeholder="请选择厂家"
                @change="handleBrandChange"
                allow-clear
              >
                <a-select-option
                  v-for="item in brandList"
                  :key="item.brandId"
                  :value="item.brandName"
                >
                  {{ item.brandName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="型号:">
              <a-select
                v-model:value="where.model"
                placeholder="请选择型号"
                allow-clear
              >
                <a-select-option
                  v-for="item in modelList"
                  :key="item.brandId"
                  :value="item.model"
                >
                  {{ item.model }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="在线状态" name="onlineState">
              <a-select
                v-model:value="where.onlineState"
                placeholder="请选择在线状态"
              >
                <a-select-option
                  v-for="item in onlineStateList"
                  :key="item.dictDataCode"
                  :value="item.dictDataCode"
                  >{{ item.dictDataName }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row v-if="expand">
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="协议类型" name="protocolType">
              <a-select
                v-model:value="where.protocolType"
                placeholder="请选择协议类型"
              >
                <a-select-option
                  :value="item.dictDataCode"
                  v-for="item in protocolTypeList"
                  :key="item.dictDataCode"
                  >{{ item.dictDataName }}</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="关联柜编号:">
              <a-input
                v-model:value.trim="where.associatedCabinetCode"
                placeholder="请输入关联柜编号"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="换电柜地址:">
              <a-input
                v-model:value.trim="where.cabinetAddress"
                placeholder="请输入换电柜地址"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="12" :sm="24" :xs="24">
            <a-form-item label="CCID:">
              <a-input
                v-model:value.trim="where.CCID"
                placeholder="请输入CCID号"
                allow-clear
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"
              ><i class="iconfont icon-zaitu"></i>查询</a-button
            >
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button
            >
            <span
              v-if="!expand"
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              展开<i class="iconfont icon-xiangxiajiantou"></i>
            </span>
            <span
              v-else
              @click="expandForm"
              style="color: #1890ff; cursor: pointer"
            >
              收起<i class="iconfont icon-xiangshang_jiantou"></i>
            </span>
          </a-space>
        </a-row>
      </a-form>
      <!-- 表格 -->
      <ud-pro-table
        ref="table"
        row-key="cabinetId"
        :datasource="datasource"
        :columns="columns"
        :scroll="{ x: 'max-content' }"
      >
        <!-- <template #pointName="{ record }">
          <router-link :to="'/point/detail?id=' + record.pointId">
            {{ record.pointName }}
          </router-link>
        </template> -->
        <template #toolbar>
          
        </template>
        <template #protocolType="{ record }">
          <span>
            {{ typeProtocol(record.protocolType) }}
          </span>
        </template>
        <template #state="{ record }">
          <span>
            {{
              record.state === 'using'
                ? '租赁中'
                : record.state === 'stock'
                ? '库存'
                : record.state === 'repair'
                ? '维修中'
                : ''
            }}
          </span>
        </template>
        <template #onlineState="{ record }">
          <div>
            {{
              record.onlineState === '1'
                ? '在线'
                : record.onlineState === '0'
                ? '离线'
                : ''
            }}
          </div>
        </template>
        <template #action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
              更多操作
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu @click="moreOnClick">
                <a-menu-item
                  key="数据监控"
                  @click="
                    navTo('/zulin/assets/real-time-monitoring', {
                      cabinetCode: record.cabinetCode
                    })
                  "
                  >数据监控</a-menu-item
                >
                <a-menu-item key="编辑" @click="addEdit(record)"
                  >编辑</a-menu-item
                >
                <a-menu-item key="图片" @click="lookPhoto(record)"
                  >图片</a-menu-item
                >
                <a-menu-item key="地图位置">
                  <router-link
                    style="color: black"
                    :to="{
                      path: '/zulin/assets/marker-detail',
                      query: { cabinetCode: record.cabinetCode }
                    }"
                    >地图位置</router-link
                  >
                </a-menu-item>
                <a-menu-item key="远程监控" @click="control(record)"
                  >远程监控</a-menu-item
                >
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </ud-pro-table>
    </a-card>
    <a-modal
      v-model:visible="visibleEdit"
      title="编辑"
      :width="680"
      @ok="handleEditOk"
      centered
    >
      <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
        <a-form-item label="换电柜名称">
          <a-input
            v-model:value="customerForm.cabinetName"
            placeholder="请输入换电柜名称"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="换电柜编号">
          <a-input
            v-model:value="customerForm.cabinetCode"
            placeholder="请输入换电柜编号"
            :disabled="true"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="所属租赁点:" name="pointId">
          <a-select
            v-model:value="customerForm.pointId"
            placeholder="请选择所属租赁点"
            allow-clear
          >
            <a-select-option
              v-for="item in pointList"
              :key="item.pointId"
              :value="item.pointId"
            >
              {{ item.pointName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="换电柜地址">
          <a-input
            v-model:value="customerForm.address"
            placeholder="请输入换电柜地址"
          >
          </a-input>
        </a-form-item>
        <a-form-item label="地图坐标:" name="coordinate">
          <a-button type="primary" @click="coordinate">地图选点</a-button>
          <div>
            经度: {{ customerForm.longitude }} 纬度: {{ customerForm.latitude }}
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      :width="680"
      :visible="showPostion"
      title="地图选址"
      :body-style="{ paddingBottom: '8px' }"
      @cancel="cancelPosition"
      footer=""
    >
      <Map
        @done="setPosition"
        v-model:position="position"
        id="cabinetAddress"
      />
    </a-modal>
    <a-modal
      v-model:visible="visiblePhoto"
      title="图片"
      :width="680"
      @ok="handlePhotoOk"
      centered
    >
      <a-form-item label="图片">
        <a-upload
          list-type="picture-card"
          v-model:file-list="photoList"
          :customRequest="uploadFile"
          :remove="removeFile"
          @change="handleImageChange"
          @preview="handlePreview"
        >
          <div v-if="photoList.length < 3">
            <plus-outlined />
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <a-modal
          :visible="previewVisible"
          :footer="null"
          :bodyStyle="{paddingTop:'50px'}"
          @cancel="previewVisible = false"
        >
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </a-form-item>
    </a-modal>
    <a-modal
      v-model:visible="visibleControl"
      title="远程监控"
      :width="680"
      centered
      :footer="null"
    >
      <a-row style="margin-bottom: 10px" type="flex" justify="space-around">
        <a-col :span="6">
          <span class="btn-sty1" @click="operation('reset', '重启')">重启</span>
        </a-col>
        <a-col :span="6"
          ><span class="btn-sty1" @click="operation('cabVol', '音量控制')"
            >音量控制</span
          ></a-col
        >
        <a-col :span="6">
          <span class="btn-sty1" @click="operation('cabSoc', '换电阈值')"
            >换电阈值</span
          ></a-col
        >
      </a-row>
      <!-- <a-row style="margin-bottom: 10px" type="flex" justify="space-around">
        <a-col :span="6">
          <span class="btn-sty1">启用</span>
        </a-col>
        <a-col :span="6"><span class="btn-sty2">禁用</span></a-col>
        <a-col :span="6"> <span class="btn-sty1">服务器校时</span></a-col>
      </a-row>
      <a-row style="margin-left: 25px">
        <a-col :span="6">
          <span class="btn-sty1">充电倍率调节</span>
        </a-col>
      </a-row> -->
    </a-modal>
    <a-modal
      v-model:visible="visibleCode"
      title="二维码"
      :width="300"
      :footer="null"
      centered
    >
      <a-image :width="250" :height="250" src="/static/code.png" />
      <div class="downLoad">
        <span> 下载 </span>
      </div>
    </a-modal>
    <a-modal
      v-model:visible="operationShow"
      :title="operationTitle"
      :width="400"
      @ok="operationOK"
      centered
    >
      <div v-if="operationType === 'reset'">确定下发重启命令?</div>
      <a-slider
        v-if="operationType === 'cabVol'"
        id="cabVol"
        v-model:value="cabVol"
        :tooltip-visible="operationShow"
      />
      <a-slider
        v-if="operationType === 'cabSoc'"
        id="cabSoc"
        v-model:value="cabSoc"
        :tooltip-visible="operationShow"
      />
    </a-modal>
  </div>
</template>
<script>
import * as chargeCabinetApi from '@/api/rnt/chargeCabinet'
import * as chargeCabinetModelApi from '@/api/rnt/chargeCabinetModel'
import * as operatorApi from '@/api/rnt/operator'
import * as pointApi from '@/api/rnt/point'
import { defineComponent } from 'vue'
import { DownOutlined, PlusOutlined } from '@ant-design/icons-vue'
import setting from '@/config/setting'
import Map from '@/components/map/addressPicker'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default defineComponent({
  name: 'chargeCabinetIndex',
  components: { DownOutlined, Map, PlusOutlined },
  data() {
    return {
      uploadUrl: setting.uploadUrl,
      photoList: [],
      operationShow: false, // 远程监控弹出层
      operationTitle: '', // 远程监控弹出层标题
      operationType: '',
      cabVol: '50',
      cabSoc: '80',
      previewImage: '',
      previewVisible: false,
      showPostion: false,
      position: {},
      expand: false,
      customerForm: {},
      visibleEdit: false,
      visiblePhoto: false,
      visibleControl: false,
      visibleCode: false,
      name: 'chargeCabinet',
      // 表格列配置
      columns: [
        {
          title: '编号',
          key: 'index',
          width: 48,
          customRender: ({ index }) => this.$refs.table.tableIndex + index
        },
        // 换电柜编码
        {
          title: '充电柜编码',
          dataIndex: 'cabinetCode',
          sorter: true
        },
        // 换电柜名称
        {
          title: '充电柜名称',
          dataIndex: 'cabinetName',
          sorter: true
        },
        // 厂家
        {
          title: '厂家',
          dataIndex: 'brand',
          sorter: true
        },
        // 型号
        {
          title: '型号',
          dataIndex: 'model',
          sorter: true
        },
        {
          title: '在柜电池数',
          dataIndex: 'batNum',
          sorter: true
        },
        {
          title: '换电柜地址',
          dataIndex: 'address',
          sorter: true
        },
        {
          title: '换电柜状态',
          slots: {
            customRender: 'state'
          },
          sorter: true
        },
        {
          title: '在线状态',
          // dataIndex: 'onlineState',
          sorter: true,
          slots: {
            customRender: 'onlineState'
          }
        },
        // 充电柜协议
        {
          title: '充电柜协议',
          dataIndex: 'protocolType',
          sorter: true
          // slots: { customRender: 'protocolType' }
        },
        {
          title: '版本号',
          dataIndex: 'version',
          sorter: true
        },
        {
          title: 'CCID',
          dataIndex: 'CCID',
          sorter: true
        },
        {
          title: '关联柜编号',
          dataIndex: 'associatedCabinetCode',
          sorter: true
        },
        {
          title: '数据时间',
          dataIndex: 'updateTime',
          sorter: true
        },
        // 运营商名称
        {
          title: '运营商名称',
          dataIndex: 'operatorName',
          sorter: true
        },
        // 所属租赁点名称
        {
          title: '所属租赁点名称',
          dataIndex: 'pointName',
          sorter: true
          // slots: { customRender: 'pointName' }
        },
        // 操作
        {
          title: '操作',
          key: 'action',
          width: 200,
          align: 'center',
          slots: { customRender: 'action' }
        }
      ],
      // 运营商list
      operatorList: [],
      // 厂家list
      brandList: [],
      // 型号list
      modelList: [],
      pointList: [],
      onlineStateList: [],
      protocolTypeList: [],
      // 表格搜索条件
      where: {},
      // 当前编辑数据
      current: null,
      // 编辑表单提交状态
      editLoading: false
    }
  },
  mounted() {
    this.queryOperatorList()
    this.queryBrandList()
    this.queryModelList()
    this.queryPoint()
    this.queryOnlineState()
    this.queryProtocolType()
  },
  methods: {
    lookCode(record) {
      this.visibleCode = true
      this.customerForm = record
    },
    // 远程操作完成回调 // 重启  // 音量控制 // 换电阈值
    operationOK() {
      if (this.operationType === 'reset') {
        chargeCabinetApi
          .reset(this.customerForm)
          .then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.operationShow = false
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      } else if (this.operationType === 'cabVol') {
        this.customerForm.value = String(this.cabVol)
        chargeCabinetApi
          .cabVol(this.customerForm)
          .then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.operationShow = false
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      } else if (this.operationType === 'cabSoc') {
        this.customerForm.value = String(this.cabSoc)
        chargeCabinetApi
          .cabSoc(this.customerForm)
          .then((res) => {
            if (res.code === 0) {
              this.$message.success(res.msg)
              this.operationShow = false
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      }
    },
    operation(type, title) {
      this.operationShow = true
      this.operationType = type
      this.operationTitle = title
    },
    // 远程监控
    control(record) {
      this.visibleControl = true
      this.customerForm.cabinetCode = record.cabinetCode
    },
    coordinate() {
      this.postion = this.customerForm
      this.showPostion = true
    },
    setPosition(data) {
      this.customerForm.longitude = data.longitude
      this.customerForm.latitude = data.latitude
      this.showPostion = false
    },
    cancelPosition() {
      this.showPostion = false
    },
    lookPhoto(record) {
      this.visiblePhoto = true
      this.customerForm = record
      this.photoList = []
      if (this.customerForm.pics) {
        const picsUrl = this.customerForm.pics.split(',')
        picsUrl.map((item, index) => {
          this.photoList.push({
            name: 'image.png',
            status: 'done',
            url: item,
            uid: index
          })
        })
      }
    },
    // 图片改变事件监听
    handleImageChange(file) {
      console.log(file)
    },
    // 确认修改图片
    handlePhotoOk() {
      chargeCabinetApi
        .save(this.customerForm, true)
        .then((res) => {
          if (res.code === 0) {
            this.visiblePhoto = false
            this.customerForm = {}
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    removeFile(file) {
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            if (this.photoList.length > 0) {
              const urls = this.photoList.map((item) => {
                return item.url
              })
              if (urls.length > 0) {
                this.customerForm.pics = urls.join(',')
              }
            } else {
              this.customerForm.pics = ''
            }
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    uploadFile(data) {
      const formData = new FormData()
      formData.append('file', data.file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            this.photoList[this.photoList.length - 1] = {
              ...this.photoList[this.photoList.length - 1],
              status: 'done',
              url: res.data.location
            }
            const urls = this.photoList.map((item) => {
              return item.url
            })
            if (urls.length > 0) {
              this.customerForm.pics = urls.join(',')
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    addEdit(record) {
      this.visibleEdit = true
      this.customerForm = record
    },
    // 编辑成功确认按钮
    handleEditOk() {
      chargeCabinetApi
        .save(this.customerForm, true)
        .then((res) => {
          if (res.code === 0) {
            this.visibleEdit = false
            this.customerForm = {}
            this.reload()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 路由跳转
    navTo(path, query) {
      this.$router.push({ path, query })
    },
    datasource(option, callback) {
      chargeCabinetApi
        .page({
          ...this.where,
          page: option.page,
          limit: option.limit
        })
        .then((res) => {
          if (res.code === 0) {
            callback(res.data, res.count)
          } else {
            callback(res.msg)
          }
        })
        .catch((e) => {
          callback(e.message)
        })
    },
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload({ page: 1 })
    },
    /* 重置搜索 */
    reset() {
      this.where = {}
      this.queryOperatorList()
      this.queryBrandList()
      this.queryModelList()
      this.$nextTick(() => {
        this.reload()
      })
    },
    // 查询所有协议类型
    queryProtocolType() {
      chargeCabinetApi
        .dictData({ dictCode: 'cabinetProtocol' })
        .then((res) => {
          if (res.code === 0) {
            this.protocolTypeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询所有在线状态
    queryOnlineState() {
      chargeCabinetApi
        .dictData({ dictCode: 'onlineState' })
        .then((res) => {
          if (res.code === 0) {
            this.onlineStateList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询所属运营商List */
    queryOperatorList() {
      operatorApi
        .all()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询所属租赁点 */
    queryPoint() {
      pointApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询换电柜厂家List */
    queryBrandList() {
      chargeCabinetModelApi
        .brand()
        .then((res) => {
          if (res.code === 0) {
            this.brandList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询换电柜厂家型号
    queryModelList() {
      chargeCabinetModelApi
        .model()
        .then((res) => {
          if (res.code === 0) {
            this.modelList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 监听运营商改变
    changeOperator(value) {
      this.where.brand = null
      this.where.model = null
      chargeCabinetModelApi
        .brand({ operatorId: value })
        .then((res) => {
          if (res.code === 0) {
            this.brandList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 监听厂家改变
    handleBrandChange(value) {
      this.where.model = null
      // 查找当前厂家充电柜型号
      chargeCabinetModelApi
        .model({ brandName: value, operatorId: this.where.operatorId })
        .then((res) => {
          if (res.code === 0) {
            this.modelList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
})
</script>

<style scoped lang='less'>
.downLoad {
  margin-top: 20px;
  span {
    color: #1890ff;
    border: 1px solid #1890ff;
    padding: 5px 20px;
    background-color: #f3f7f8;
    border-radius: 50px;
  }
}
.form {
  background-color: #fff;
  .btn-wrap {
    .iconfont {
      margin-right: 8px;
    }
  }
}
.btn-sty1 {
  color: #3585bd;
  background-color: #e6f0f7;
  width: 150px;
  text-align: center;
  display: inline-block;
  padding: 5px 0;
  border: 1px solid #3585bd;
  border-radius: 50px;
  &:hover {
    cursor: pointer;
  }
}
.btn-sty2 {
  color: red;
  background-color: #ffe5e5;
  display: inline-block;
  width: 150px;
  padding: 5px 0;
  text-align: center;
  border: 1px solid red;
  border-radius: 50px;
  &:hover {
    cursor: pointer;
  }
}
</style>
